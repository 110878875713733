//分区管理
import { axios } from '@/utils/request'

//分区分页查询
export function partitionPage(parameter) {
  return axios({
    url: '/partition/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}

//企业详情
export function enterpriseDetail(parameter) {
  return axios({
    url: '/enterprise/detail',
    method: 'post',
    data: parameter,
  })
}

//新增分区
export function partitionAdd(parameter) {
  return axios({
    url: '/partition/add',
    method: 'post',
    data: parameter,
  })
}
//删除分区
export function partitionDelete(parameter) {
  return axios({
    url: '/partition/delete',
    method: 'post',
    data: parameter,
  })
}
//编辑分区
export function partitionEdit(parameter) {
  return axios({
    url: '/partition/edit',
    method: 'post',
    data: parameter,
  })
}

//分区商品根据不同代理商设置不同的市场价和规格属性
export function partitionGoodsSpecificationSet(parameter) {
  return axios({
    url: '/goods/setPartitionGoods',
    method: 'post',
    data: parameter,
  })
}

//分区商品根据不同代理商获取不同的市场价和规格属性
export function partitionGoodsSpecification(parameter) {
  return axios({
    url: '/goods/getPartitionGoods',
    method: 'post',
    data: parameter,
  })
}
