<template>
  <a-modal
    title="代理商设置"
    width="50%"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="区域" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select
                v-decorator="['areaId', { rules: [{ required: true, message: '请选择区域！' }] }]"
                allow-clear
                placeholder="请选择区域"
                style="width: 40%; min-width: 100px"
                @change="handleAreaChange"
              >
                <a-select-option v-for="(item, index) in areas" :key="index" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="市场价" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear>
              <a-input-number
                placeholder="请输入市场价"
                :min="0"
                :max="9999999"
                :precision="2"
                style="width: 40%; min-width: 100px"
                v-decorator="['marketPrice', { rules: [{ required: true, message: '请输入市场价!' }] }]"
              />
            </a-form-item>
            <a-form-item label="商品规格" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-table
                :columns="columns"
                :data-source="partitionAreaSpecificationsList"
                :pagination="false"
                rowKey="id"
                bordered
              >
                <span class="prcture" slot="tupian" slot-scope="text, record, index">
                  <viewer :images="[record.image]">
                    <img
                      :src="record.image"
                      style="width: 50px; height: 50px; border: 0.5px solid #ccc; padding: 5px; margin: 3px"
                      alt=""
                    />
                  </viewer>
                </span>

                <span slot="price" slot-scope="text, record">
                  <a-input-number :min="0" :max="9999999" :precision="2" v-model="record.price" placeholder="请输入" />
                </span>
                <span slot="minPrice" slot-scope="text, record">
                  <a-input-number
                    :min="0"
                    :max="9999999"
                    :precision="2"
                    v-model="record.minPrice"
                    placeholder="请输入"
                  />
                </span>
                <span slot="costPrice" slot-scope="text, record">
                  <a-input-number
                    :min="0"
                    :max="9999999"
                    :precision="2"
                    v-model="record.costPrice"
                    placeholder="请输入"
                  />
                </span>
                <span slot="commissionRatio" slot-scope="text, record">
                  <a-input-number
                    :min="0"
                    :max="9999999"
                    :precision="2"
                    :step="0.1"
                    v-model="record.commissionRatio"
                    placeholder="请输入"
                  />
                </span>
                <span slot="kucun" slot-scope="text, record">
                  {{ record.stock }}
                </span>
                <span slot="specname" slot-scope="text, record">
                  {{ record.name }}
                </span>
              </a-table>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { partitionGoodsSpecification, partitionGoodsSpecificationSet } from '@/api/modular/mallLiving/partition'
import { areaPage } from '@/api/modular/mallLiving/area'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'

const columns = [
  {
    title: '规格',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'specname',
    },
  },
  {
    title: '图片',
    dataIndex: 'image',
    key: 'image',
    scopedSlots: {
      customRender: 'tupian',
    },
  },

  {
    title: '价格',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: {
      customRender: 'price',
    },
  },
  {
    title: '业务员可调整的最低价',
    dataIndex: 'minPrice',
    key: 'minPrice',
    scopedSlots: {
      customRender: 'minPrice',
    },
  },
  {
    title: '成本价',
    dataIndex: 'costPrice"',
    key: 'costPrice',
    scopedSlots: {
      customRender: 'costPrice',
    },
  },
  {
    title: '业务员卖出平台的商品，利润拿出去和代理商分的比例',
    dataIndex: 'commissionRatio',
    key: 'commissionRatio',
    scopedSlots: {
      customRender: 'commissionRatio',
    },
  },
  {
    title: '库存',
    key: 'stock',
    dataIndex: 'stock',
    scopedSlots: {
      customRender: 'kucun',
    },
  },
]

export default {
  props: {
    partitionId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      //商品id
      id: '',
      //区域列表
      areas: [],
      //规格列表
      partitionAreaSpecificationsList: [],
      //规格图片
      spec: '',
      specLoading: false,
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      columns,
    }
  },
  mounted() {
    this.getAreas()
  },
  methods: {
    handleAreaChange(event) {
      console.log('event', event)
      this.getPartitionGoodsSpecification(event)
    },

    chooseSpecIndex(index) {
      this.specIndex = index
    },

    //自定义规格图片上传
    customSpecRequest(filedata) {
      let that = this
      that.specLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            that.partitionAreaSpecificationsList[that.specIndex].image = list[0]
            that.specLoading = false
          }
        }
      })
    },
    addSpecData() {
      this.partitionAreaSpecificationsList.push({
        stock: '',
        name: '',
        image: '',
        price: '',
        minPrice: '',
        costPrice: '',
        commissionRatio: '',
      })
    },
    getAreas() {
      areaPage({
        queryParam: {},
        page: {},
      }).then((res) => {
        console.info('areas', res)
        if (res.success && res.data) {
          this.areas = res.data.rows
        }
      })
    },
    // 初始化方法
    edit(record) {
      console.info('record', record)
      const { id } = record
      this.id = id
      this.visible = true
    },
    getPartitionGoodsSpecification(areaId) {
      partitionGoodsSpecification({
        areaId,
        goodsId: this.id,
      }).then((res) => {
        console.log('规格', res)
        const { partitionAreaGoods = {}, partitionAreaSpecificationsList = [] } = res.data || {}

        this.form.setFieldsValue({
          marketPrice: partitionAreaGoods ? partitionAreaGoods.marketPrice : 0,
        })

        if (!partitionAreaSpecificationsList || !Array.isArray(partitionAreaSpecificationsList)) {
          this.partitionAreaSpecificationsList = [
            {
              stock: '',
              name: '',
              image: '',
              price: '',
              minPrice: '',
              costPrice: '',
              commissionRatio: '',
            },
          ]
        } else {
          this.partitionAreaSpecificationsList = partitionAreaSpecificationsList
        }
      })
    },

    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          const { flashSaleBegin, blacklistAreaIds, ...rest } = values

          partitionGoodsSpecificationSet({
            ...rest,
            goodsId: this.id,
            partitionAreaSpecificationsList: this.partitionAreaSpecificationsList,
          }).then((res) => {
            if (res.success) {
              this.$message.success('设置成功！')
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.partitionAreaSpecificationsList = []
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
/deep/ .ant-form-item-control-wrapper {
  overflow-x: auto;
}

.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.clearfix {
  width: 100px;
  height: 100px;
}
</style>
